<template>
    <div class="app-container">
        <!-- 搜索块 -->
        <el-form :inline="true" class="demo-form-inline" @submit.prevent>
            <el-form-item label="单位:">
                <!-- <el-select v-model="search.institutionId" filterable size="small" clearable placeholder="请选择单位"
                    class="form-line-item">
                    <el-option v-for="(item,index) in institutionList" :key='index' :label="item.institutionName"
                        :value="item.institutionId"></el-option>
                </el-select> -->
                <el-input placeholder="请输入单位名称" v-model="search.institutionName" size='small' class="form-line-item" @keyup.enter.native.prevent="batchSearch">
                        </el-input>
            </el-form-item>
            <el-form-item style="margin-left:10px;">
                <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
                    搜索
                </el-button>
                <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
                    重置
                </el-button>
            </el-form-item>
        </el-form>

        <!-- 工具栏 -->
        <vxe-toolbar style="padding:0px 10px;">
            <template #buttons>
                <el-button type="success" size="mini" icon="el-icon-plus" @click="insertSchoolHandle()">添加</el-button>
            </template>
        </vxe-toolbar>

        <vxe-table class="mytable-scrollbar custom-table" auto-resize :loading="loading"
            :row-config="{isHover: true,isCurrent: true}"
            :tree-config="{transform: true, rowField: 'institutionId', parentField: 'parentId'}" :data="tableData">
            <vxe-column type="seq" title="序号" width="200"></vxe-column>
            <vxe-column field="institutionId" title="ID" width="200" tree-node></vxe-column>
            <vxe-column field="institutionName" title="名称" width="300" sortable></vxe-column>
            <vxe-column field="institutionName" title="匿名" width="200">
                <template #default="{row,rowIndex,$rowIndex}">
                    <div @click.stop="changeDisplay(row)"   v-if="row.parentId">
                        <vxe-switch v-model="row.isDisplay" :open-value="0" open-label="是" :close-value="1" close-label="否">
                        </vxe-switch>
                    </div>
                </template>
            </vxe-column>
            <vxe-column title="操作">
                <template #default="{row,rowIndex,$rowIndex}">
                    <div v-if="row.parentId">
                        <el-button size="mini" type="text" @click="ruleCustomClick(row,$rowIndex)">规则定制
                        </el-button>
                        <!-- <el-button size="mini" type="text" @click="manageCustomClick(row,$rowIndex)">管理定制
                        </el-button> -->
                        <el-button size="mini" type="text" @click="insertHandle(row)">添加学位类型
                        </el-button>
                        <el-button size="mini" type="text" @click="modifyName(row)">修改单位名称
                        </el-button>
                    </div>
                    <div v-else>
                        <el-button size="mini" type="text" @click="insertCollegeHandle(row)">添加子单位
                        </el-button>
                        <el-button size="mini" type="text" @click="modifyName(row)">修改单位名称
                        </el-button>
                    </div>
                </template>
            </vxe-column>
        </vxe-table>

        <vxe-pager perfect align='right' :current-page.sync="page.pageIndex" :page-size.sync="page.pageSize"
            :total="page.total" :page-sizes="page.pageSizes"
            :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
            @page-change="handlePageChange">
            <template #left>
                <vxe-button size="small" @click="firstPage">首页
                </vxe-button>
            </template>
        </vxe-pager>

        <vxe-modal v-model="insertSchoolModel" :position="{top: 5}" width="500" :show-footer='true' show-zoom resize
            :transfer='true'>
            <template #title>
                添加学校
            </template>
            <vxe-form :data="schoolName" title-align="right" title-width="100">
                <vxe-form-item field="paperType" title="学校名称" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input v-model="data.name" placeholder="请输入" type="text">
                        </vxe-input>
                    </template>
                </vxe-form-item>
            </vxe-form>
            <template #footer>
                <el-button @click="cfmInsertSchoolHandle" type="primary" size="small">确认</el-button>
            </template>
        </vxe-modal>

        <vxe-modal v-model="insertCollegeModel" :position="{top: 5}" width="500" :show-footer='true' show-zoom resize
            :transfer='true'>
            <template #title>
                添加子单位
            </template>
            <vxe-form :data="college" title-align="right" title-width="100">
                <vxe-form-item field="name" title="单位名称" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input v-model="data.name" placeholder="请输入" type="text">
                        </vxe-input>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="account" title="账号" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input v-model="data.account" placeholder="请输入" type="text">
                        </vxe-input>
                    </template>
                </vxe-form-item>
            </vxe-form>
            <template #footer>
                <el-button @click="cfmInsertCollegeHandle" type="primary" size="small">确认</el-button>
            </template>
        </vxe-modal>

        <vxe-modal v-model="insertDegreeModel" :position="{top: 5}" width="700" show-zoom resize :transfer='true'>
            <template #title>
                添加学位类型
            </template>
            <el-descriptions class="margin-top" :labelStyle="{width:'20%'}" :contentStyle="{width:'80%'}" :column="1"
                size="medium" border>
                <el-descriptions-item>
                    <template slot="label">
                        已有学位类型
                    </template>
<!--                      <template v-for="item in degreeList" :key="item.degreeId">-->
                          <el-tag v-for="item in degreeList" :key="item.degreeId" type="primary" style="margin-right:10px;margin-bottom:10px;" closable @close="handleDelete(item.degreeId)">{{item.degreeName}}
                          </el-tag>
<!--                      </template>-->
<!--                    <template v-for="item in degreeList">-->
<!--                      <el-tag type="primary" style="margin-right:10px;margin-bottom:10px;" closable>{{item.degreeName}}-->
<!--                      </el-tag>-->
<!--                    </template>-->
                </el-descriptions-item>
            </el-descriptions>
            <vxe-form :data="degree" title-align="right" title-width="100">
                <vxe-form-item field="name" title="学位类型" :span="18" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input v-model="data.name" placeholder="请输入" type="text">
                        </vxe-input>
                    </template>
                </vxe-form-item>
                <vxe-form-item :span="6" :item-render="{}">
                    <template #default="{ data }">
                        <el-button @click="insertDegree" type="primary" size="small">添加</el-button>
                    </template>
                </vxe-form-item>
            </vxe-form>
        </vxe-modal>

        <vxe-modal v-model="modifyNameModel" :position="{top: 5}" width="500" :show-footer='true' show-zoom resize
            :transfer='true'>
            <template #title>
                添加子单位
            </template>
            <vxe-form :data="modifyInstitution" title-align="right" title-width="100">
                <vxe-form-item field="name" title="单位名称" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input v-model="data.name" placeholder="请输入" type="text">
                        </vxe-input>
                    </template>
                </vxe-form-item>
            </vxe-form>
            <template #footer>
                <el-button @click="cmfModifyName" type="primary" size="small">确认</el-button>
            </template>
        </vxe-modal>
    </div>
</template>

<script>
    export default {
        name: 'schoolCustom_index',
        data() {
            return {
                search: {
                    institutionName: ''
                },
                institutionList: [{
                    institutionId: '1',
                    institutionName: '南京理工大学'
                }],
                loading: false,
                tableData: [],
                //分页
                page: {
                    pageIndex: 1,
                    pageSize: 10,
                    pageCount: 40, //自己用
                    pageSizes: [10, 20, 50, 100, 200, 500],
                    total: 100
                },
                // 添加学校弹窗
                insertSchoolModel: false,
                schoolName: {
                    name: ''
                },
                // 添加学院弹窗
                insertCollegeModel: false,
                college: {
                    parentId: null,
                    name: '',
                    account: ''
                },
                // 学位类型
                insertDegreeModel: false,
                degree: {
                    institutionId: '',
                    name: '',
                    id:''
                },
                // degreeList: [1, 1, 1],
                // degreeList: {
                //     institutionId: '',
                //     name: '',
                //     id:''
                // },
                degreeList: [],
                // 修改单位名称
                modifyNameModel:false,
                modifyInstitution:{
                    name:''
                },

            }
        },
        created() {
            window.addEventListener('keydown', this.handleKeyPress);
            this.batchSearch();
        },
        computed: {},
        methods: {
            handleKeyPress(event) {
                // if (event.keyCode === 13) {
              if(event.key === 'Enter'){
                    this.batchSearch()
                }
            },
            getAllInstitutions() {
                let params = {
                    institutionName:this.search.institutionName,
                    pageIndex: this.page.pageIndex,
                    pageSize: this.page.pageSize
                }
                this.$api.schoolCustom.getAllInstitutions(params)
                    .then(res => {
                        this.tableData = res.data.data;
                        this.page.total = res.data.count;
                    })
                    .catch(err => {
                        this.$message.warning('接口错误');
                    })
            },
            batchSearch() {
                this.getAllInstitutions();
            },
            resetHandle() {
                this.search = {
                    institutionId: ''
                }
            },
            // 点击添加学校按钮
            insertSchoolHandle() {
                this.insertSchoolModel = true;
            },
            // 点击确认按钮
            cfmInsertSchoolHandle() {
                let params = new URLSearchParams()
                console.log(this.schoolName)
                params.append('schoolName', this.schoolName.name)
                this.$api.schoolCustom.insertSchool(params)
                    .then(res => {
                      if (res.data.code == 200){
                        this.$message.success("添加成功");
                      }
                    })
                    .catch(err => {
                      this.$message.warning("服务器维护");
                    })
            },
            // 点击添加学院按钮
            insertCollegeHandle(row) {
                this.college = {
                    parentId: row.institutionId,
                    name: '',
                    account: ''
                }
                this.insertCollegeModel = true;
            },
            // 点击添加学位类型
            insertHandle(row) {
                this.degree = {
                    institutionId: row.institutionId,
                    name: ''
                }
                let params = {
                    institutionId: row.institutionId
                };
                this.$api.schoolCustom.getDegree(params)
                    .then(res => {
                        this.degreeList = res.data.data;
                      console.log("degreelist1", this.degreeList)
                        this.insertDegreeModel = true;
                    }).catch(err => {
                        this.$message.warning("获取学位类型错误");
                    })
              console.log("degreelist2", this.degreeList)
            },

            getDegree() {
                let params = {
                    institutionId: this.degree.institutionId
                };
                this.$api.schoolCustom.getDegree(params)
                    .then(res => {
                        this.degreeList = res.data.data;
                    }).catch(err => {
                        this.$message.warning("获取学位类型错误");
                    })

            },
            insertDegree() {
                if (!this.degree.name) {

                    this.$message.warning("请输入学位类型！");
                    return;
                }
                for (let i = 0; i < this.degreeList.length; i++) {
                    if (this.degreeList[i].degreeName == this.degree.name) {
                        this.$message.warning("已存在该学位类型！");
                        return;
                    }
                }
                let params = {
                    institutionId: this.degree.institutionId,
                    degreeName: this.degree.name
                };
                this.$api.schoolCustom.postAddDegree(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.getDegree();
                            this.degree.name = '';
                            this.$message.success("添加成功！");
                        } else {
                            this.$message.warning("添加失败！");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护!");
                    })
            },
            handleDelete(degreeId) {
                if (!degreeId) {
                  this.$message.warning("无法删除，degreeId 不能为空！");
                  return;
                }

                // 找到对应的 degreeName 进行提示（可选）
                // const degreeItem = this.degreeList.find(item => item.degreeId === degreeId);
                // const degreeName = degreeItem ? degreeItem.degreeName : "该学位类型";

                // 调用后端 API 修改 is_deleted 字段
                let params = {
                  degreeId: degreeId
                };
                console.log("id", degreeId)
                this.$api.schoolCustom.postDeleteDegree(params)
                    .then(res => {
                      console.log(res.data.code)
                      if (res.data.code == 200) {
                        this.getDegree(); // 重新获取学位列表
                        this.$message.success(`删除成功！`);
                      } else {
                        this.$message.warning(`删除失败！`);
                      }
                    })
                    .catch(err => {
                      console.error("删除失败:", err);
                      this.$message.warning("服务器维护！");
                    });
            },
            //修改匿名
            changeDisplay(row) {
                // let params = {
                //     institutionId: row.institutionId,
                //     isDisplay: row.isDisplay
                // }
                let params = new FormData();
                params.append('institutionId',row.institutionId);
                params.append('isDisplay',row.isDisplay);
                this.$api.schoolCustom.modifyInstitutionIsDisplay(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("修改成功！");

                        } else {
                            this.$message.warning("修改失败！");
                        }
                    })
            },
            modifyName(row){
                this.modifyInstitution.name = row.institutionName;
                this.modifyInstitution.institutionId = row.institutionId;
                this.modifyNameModel = true;
            },
            cmfModifyName(){
                let params = new FormData();
                params.append('institutionId',this.modifyInstitution.institutionId);
                params.append('institutionName',this.modifyInstitution.name);
                this.$api.schoolCustom.modifyInstitutionName(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("修改成功！");
                        } else {
                            this.$message.warning("修改失败！");
                        }
                        this.modifyNameModel = false;
                        this.getAllInstitutions();
                    })
            },
            // 点击确认按钮
            cfmInsertCollegeHandle() {
                if (this.college.name == '') {
                    this.$message.warning("单位名称非空！");
                    return;
                }
                if (this.college.account == '') {
                    this.$message.warning("账号非空！");
                    return;
                }
                let params = {
                    parentInstitutionId: this.college.parentId,
                    childrenInstitutionName: this.college.name,
                    account: this.college.account
                }
                this.$api.schoolCustom.postAddChildrenOrg(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("添加成功！");
                            this.getAllInstitutions();
                            this.insertCollegeModel = false;
                        } else {
                            this.$message.warning("添加失败！");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护!");
                        this.insertCollegeModel = false;
                    })
            },
            // 点击规则定制按钮
            ruleCustomClick(row, index) {
                let second = row.institutionName;
                let i = 0;
                while (this.tableData[i].institutionId != row.parentId) {
                    i++;
                }
                let first = this.tableData[i].institutionName;
                this.$store.dispatch('custom/toggleInstitution', {
                    first,
                    second
                });
                this.$router.push({
                    name: 'ruleCustom',
                    params: {
                        id: row.institutionId
                    }
                });
            },
            // 点击管理定制按钮
            manageCustomClick(row, index) {
                let second = row.institutionName;
                let i = 0;
                while (this.tableData[i].institutionId != row.parentId) {
                    i++;
                }
                let first = this.tableData[i].institutionName;
                this.$store.dispatch('custom/toggleInstitution', {
                    first,
                    second
                });
                this.$router.push({
                    name: 'manageCustom',
                    params: {
                        id: row.institutionId
                    }
                });
            },
            // 加载子单位
            loadChildrenMethod({
                row
            }) {
                return new Promise(resolve => {
                    let names = ['文学院', '历史学院', '新闻传播学院', '法学院', '商学院', '外国语学院', '政府管理学院', '国际关系学院', '信息管理学院',
                        '社会学院', '数学系',
                        '物理学院', '天文与空间科学学院', '化学化工学院', '计算机科学与技术系', '软件学院', '人工智能学院', '电子科学与工程学院（示范性微电子学院）',
                        '现代工程与应用科学学院', '环境学院',
                        '地球科学与工程学院', '地理与海洋科学学院', '大气科学学院', '生命科学学院', '医学院', '工程管理学院', '匡亚明学院', '海外教育学院',
                        '建筑与城市规划学院', '马克思主义学院',
                        '艺术学院'
                    ];
                    let code = '';
                    let arr = [];
                    names.forEach((item, index) => {
                        let num = index + 2;
                        if (num < 10) num = '0' + num;
                        arr.push({
                            id: '' + num,
                            parentId: '1',
                            name: item,
                            code: '10284' + num,
                            model: '全委托',
                            auth: [1, 2, 3, 4]
                        })
                    })
                    let type = ['学术硕士账号', '专业硕士账号', '学术博士账号', '专业博士账号']
                    let len = arr.length;
                    type.forEach((item, index) => {
                        let num = len + index;
                        arr.push({
                            id: num + '',
                            parentId: '1',
                            name: item,
                            account: '10284' + num,
                            auth: [1]
                        });
                    })
                    resolve(arr)
                })
            },
            //分页操作
            handlePageChange({
                currentPage,
                pageSize
            }) {
                console.log(currentPage, pageSize);
                this.page.pageIndex = currentPage;
                this.page.pageSize = pageSize;
                this.getAllInstitutions();
            },
            firstPage() {
                this.page.pageIndex = 1;
                this.getAllInstitutions();
            },
        },
        components: {

        }
    }
</script>

<style scoped>
    .schoolCard {
        text-align: center;
    }

    
</style>

<style>
    .custom-table .vxe-table--body-wrapper {
        height: calc(100vh - 301px);
    }
</style>